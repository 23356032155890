import React, { ChangeEvent, ReactNode, useCallback } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { useField } from "formik";
import HelpPopover from "./HelpPopover";

type Value = string | number | string[] | undefined;
export type ColSize =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

interface Props {
  col?: ColSize;
  name: string;
  type?:
    | "number"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "search"
    | "password"
    | "date"
    | "datetime-local"
    | "textarea";
  placeholder?: string;
  label?: string;
  recommendedValues?: string[];
  help?: ReactNode;
  disabled?: boolean;
  nullable?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
}

export default function TextField<V extends Value>(props: Props) {
  const [field, meta, helpers] = useField<V | null>(props.name);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "" && props.nullable) {
        helpers.setValue(null);
        return;
      }
      field.onChange(e);
    },
    [field, helpers, props.nullable]
  );

  const invalid = Boolean(meta.touched && meta.error);

  const prefix = field.name.replace(".", "-");
  const popOverId = prefix + "-help";

  let listId: string | undefined = undefined;
  if (props.recommendedValues) {
    listId = prefix + "-datalist";
  }

  return (
    <FormGroup className={props.col && `col-md-${props.col}`}>
      {props.label && <Label for={field.name}>{props.label}</Label>}
      <HelpPopover id={popOverId} text={props.help} />
      <Input
        {...field}
        value={field.value ?? ""}
        id={field.name}
        onChange={onChange}
        invalid={invalid}
        type={props.type}
        list={listId}
        placeholder={props.placeholder}
        disabled={props.disabled}
        maxLength={props.maxLength}
        min={props.min}
        max={props.max}
      />
      {props.recommendedValues && (
        <datalist id={listId}>
          {props.recommendedValues.map((v) => (
            <option key={v} value={v} />
          ))}
        </datalist>
      )}
      <FormFeedback>{meta.error}</FormFeedback>
    </FormGroup>
  );
}
